import {usePriceStore} from "~/stores/purchase/price"
import {useUserStore} from "~/stores/user"

export default defineNuxtRouteMiddleware(async (to, from) => {
    const priceStore = usePriceStore()
    const userStore = useUserStore()
    const pricesData = await priceStore.getCurrencyPrices(userStore.geoData.currency)

    if (!pricesData) {
        abortNavigation(createError({statusCode: 404, statusMessage: `Could not find prices for given currency`}))
    }
})
